import React from 'react';

import { Box } from '@core';
import { StarWithBorderIcon } from '@components/icon';

const Star = ({ filled, fillColor, emptyColor }) => (
  <Box display="flex" flexShrink={0}>
    <StarWithBorderIcon color={filled ? fillColor : emptyColor} />
  </Box>
);

const RatingStars = ({ rating, fillColor = '#292B4F', emptyColor = '#D4D4D4' }) => {
  const starIcons = [];
  for (let i = 0; i < 5; i++) {
    const fillRatio = rating - i;
    const calculatedWidth = fillRatio >= 1 ? 100 : fillRatio > 0 ? fillRatio * 100 : 0;

    starIcons.push(
      <Box display="flex" position="relative" mx={1.5} key={i}>
        <Star fillColor={fillColor} emptyColor={emptyColor} />
        <Box
          display="flex"
          position="absolute"
          top={0}
          left={0}
          overflowX="hidden"
          width={`${calculatedWidth}%`}
        >
          <Star filled fillColor={fillColor} emptyColor={emptyColor} />
        </Box>
      </Box>
    );
  }

  return <Box display="flex">{starIcons}</Box>;
};

export default RatingStars;
